<template>
  <div class="share">
    <img
      :src="codeLink"
      class="qrcode-wrapper"
    >
    <div>
      <p class="copy-text">
        微信/手机，扫一扫分享直播
      </p>
      <div class="copy-group">
        <span
          id="copy-link"
          class="copy-link"
        >{{ liveLink }}</span>
        <el-button
          ref="copy"
          data-clipboard-target="#copy-link"
          size="mini"
          type="primary"
          class="copy-btn"
          @click="copyLiveAdrr"
        >
          复制页面地址
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import Clipboard from 'clipboard';

export default {
  name: 'Share',
  props: {
    liveLink: {
      type: String,
      default: '',
    },
    codeLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      clipboard: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // this.drawQrCode();
      this.clipboard = new Clipboard(this.$refs.copy.$el);
    });
  },
  methods: {
    drawQrCode() {
      // 取不到？
      // const { clientWidth, clientHeight } = this.$refs.qrWrapper;

      QRCode.toCanvas(
        this.$refs.qrcode,
        this.liveLink,
        {
          width: 100,
          height: 100,
          margin: 2,
        },
      );
    },
    close() {
      this.emit('close');
    },
    copyLiveAdrr() {
      this.clipboard.on('success', () => {
        this.$message.success('已成功复制到剪贴板！');
        this.clipboard.destroy();
        this.clipboard = new Clipboard(this.$refs.copy.$el);
      });
      this.clipboard.on('error', () => {
        // 不支持复制
        this.$message.error('该浏览器不支持自动复制，请手动复制');
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ {
  .el-input-group__append {
    background: #3E74CA;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    color: white;
  }
}
.share {
  display: flex;

  .qrcode-wrapper {
    height: 100px;
    width: 100px;
    display: inline-block;
    position: relative;
    margin-right: 16px;
    border-radius: 4px;
  }

  .qrcode {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  .copy-link {
    display: inline-block;
    width: 250px;
    height: 32px;
    background: #151A28;
    border-radius: 4px;
    opacity: 0.7;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 32px;
    overflow: hidden;
    padding-left: 8px;
  }

  .copy-btn {
    display: inline-block;
    background: #3E74CA;
    border-radius: 0 4px 4px 0;
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    height: 32px;
    width: 96px;
    position: relative;
    left: -96px;
    padding: 0;
  }
  .copy-text {
    margin-top: 15px;
    opacity: 0.9;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 22px;
  }

  .copy-group {
    margin-top: 16px;
    display: flex;
  }
}

</style>
